import { FormInputField } from '../types'
import { h } from 'preact'

interface FieldDefintion {
  fieldDefintion: FormInputField
}

export const TextField = ({ fieldDefintion }: FieldDefintion) => (
  <label class="md-embedded-form--input-wrapper">
    {fieldDefintion?.label && (
      <div class="md-embedded-form--input-title">{fieldDefintion?.label}</div>
    )}
    <div class="md-embedded-form--input">
      <input
        name={`fields[${fieldDefintion?.indexPosition}].value`}
        class="md-embedded-form--input-el"
        placeholder={`${fieldDefintion?.placeholder || fieldDefintion?.label}`}
        tabIndex={fieldDefintion?.indexPosition}
        required={fieldDefintion?.isRequired}
        type={fieldDefintion?.dataType}
      />
      <input
        value={`${fieldDefintion?.name}`}
        name={`fields[${fieldDefintion?.indexPosition}].name`}
        type="hidden"
      />
    </div>
  </label>
)
