import { getSliderPosition } from './getSliderPosition'
import { h } from 'preact'
import { useAppContext } from '../hooks/useAppContext'
import { useFormVariant } from '../hooks/useFormVariant'

export const useFormStyles = (): h.JSX.CSSProperties | string => {
  const formVariant = useFormVariant()
  const { formDefinition } = useAppContext()
  if (formVariant === 'SLIDER') return `${getSliderPosition(formDefinition)}`

  if (formVariant === 'INLINE') return { maxWidth: 'none' }

  return {}
}
