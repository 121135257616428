import { CSSOverride } from './CSSOverride'
import { InlineForm } from './InlineForm'
import { PopupForm } from './PopupForm'
import { SliderForm } from './SliderForm'
import { h } from 'preact'
import { useAppContext } from '../hooks/useAppContext'
import { useFormVariant } from '../hooks/useFormVariant'

interface FormComponent {
  POPUP: h.JSX.Element
  INLINE: h.JSX.Element
  SLIDER: h.JSX.Element
}

export const Form = () => {
  const {
    formDefinition,
    formDefinition: { jsonConfig },
  } = useAppContext()
  const formVariant = useFormVariant()

  if (!jsonConfig) {
    return null
  }
  const formComponent: FormComponent = {
    POPUP: <PopupForm />,
    INLINE: <InlineForm />,
    SLIDER: <SliderForm />,
  }

  return (
    <div data-id={`maildroppa-form-${formDefinition.id}`}>
      <CSSOverride />
      {formComponent[formVariant]}
    </div>
  )
}
