import { FormDefinition } from '../types'
import { h } from 'preact'

export const borderPosition = (
  formDefinition: FormDefinition,
): h.JSX.CSSProperties | string => {
  const { jsonConfig } = formDefinition

  switch (jsonConfig.sliderPopupPosition) {
    case 'BOTTOM_RIGHT':
      return {
        borderBottom: 0,
        borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
      }

    case 'CENTER_RIGHT':
      return {
        borderRight: 0,
        borderRadius: `${jsonConfig.mainBorder.radius}px 0 0 ${jsonConfig.mainBorder.radius}px`,
      }

    case 'TOP_RIGHT':
      return {
        borderTop: 0,
        borderRadius: `0 0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px`,
      }

    case 'TOP_LEFT':
      return {
        borderTop: 0,
        borderRadius: `0 0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px `,
      }

    case 'TOP_CENTER':
      return {
        borderTop: 0,
        borderRadius: `0 0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px`,
      }

    case 'CENTER_LEFT':
      return {
        borderLeft: 0,
        borderRadius: `0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0`,
      }

    case 'BOTTOM_LEFT':
      return {
        borderBottom: 0,
        borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
      }

    case 'BOTTOM_CENTER':
      return {
        borderBottom: 0,
        borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
      }
    default:
      return {
        borderBottom: 0,
        borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
      }
  }
}
