/**
 * We cannot use React-based event listeners because the form might be pre-rendered.
 * It's not possible to use react hydration because we want users to be able to modify HTML.
 * So all event listeners and DOM manipulations are vanila-js.
 */
import { getFingerPrint } from '../utils/getFingerPrint'
import { FormDefinition, Maybe, SubmitFormServiceValues } from '../types'
import { exceedsFrequencyRate, setLastSeenIfNecessary } from '../utils/form-frequency'

import { submitFormService } from '../utils/submitFormService'

let globalValues = {}

const displayErrors = (errors, formId) => {
  const $fields = document.querySelectorAll(`[data-error-wrapper]`)
  $fields.forEach((item) => (item.style.display = 'none'))
  for (let fieldName in errors) {
    const $field = document.querySelector(
      `[data-error-wrapper="error-${fieldName}-${formId}"]`,
    )
    $field.innerHTML = errors[fieldName]
    $field.style.display = 'block'
    $field.style.marginTop = '-15px'
  }
}

const allowSubmit = (values) => {
  if (values.url) {
    return false
  }

  const entryTime = localStorage.getItem('entryTime')

  const timeNow = new Date()
  const entry = new Date(entryTime)
  const differenceInMilliseconds = timeNow - entry
  const differenceInSeconds = differenceInMilliseconds / 1000

  if (differenceInSeconds < 5) {
    return false
  }
  return true
}

const handleSubmit = async (
  e: Event,
  formDefinition: FormDefinition,
  formWrap: Maybe<HTMLDivElement>,
) => {
  e.preventDefault()
  if (!formWrap) {
    return null
  }

  const values = Object.fromEntries(
    new FormData(e.target as HTMLFormElement).entries(),
  )

  const gdprForm: Maybe<HTMLDivElement> = formWrap.querySelector(
    '[data-id="maildroppa-wrap-gdprForm"]',
  )
  const dynamicForm: Maybe<HTMLDivElement> = formWrap.querySelector(
    '[data-id="maildroppa-dynamicForm"]',
  )
  const footerText: Maybe<HTMLDivElement> = formWrap.querySelector(
    '[data-id="maildroppa-form--footer-text"]',
  )

  const submitButton: Maybe<HTMLDivElement> = formWrap.querySelector(
    '[data-id="submit-button"]',
  ) as HTMLButtonElement

  if (
    formDefinition?.gdprAgreement?.id &&
    gdprForm &&
    dynamicForm &&
    footerText
  ) {
    gdprForm.style.display = 'block'
    dynamicForm.style.display = 'none'
    footerText.style.display = 'none'
    globalValues = values
    return
  }

  try {
    const fingerPrint = await getFingerPrint()
    const valuesWithAgreement = {
      ...values,
      finger_print: fingerPrint,
    }

    if (submitButton) {
      submitButton.disabled = true
      submitButton.style.opacity = 0.3
    }

    const canSubmit = allowSubmit(valuesWithAgreement)
    delete valuesWithAgreement.url
    const respData = canSubmit
      ? await submitFormService(valuesWithAgreement as SubmitFormServiceValues)
      : {
          title: 'Thanks for your subscription!',
          content:
            'We have sent you an email confirmation link. Please check your inbox to confirm your email address.',
        }

    const successMessage: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-SuccessMessage"]',
    )
    const title: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-success-form--title"]',
    )
    const formFooter: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-form--footer"]',
    )
    const content: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-success-form--text"]',
    )
    const formBody: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-form--body"]',
    )

    if (respData.validationErrors) {
      displayErrors(respData.validationErrors, formDefinition.id)
      submitButton.disabled = false
      submitButton.style.opacity = 1
      return
    }

    setLastSeenIfNecessary(formDefinition)

    if (respData) {
      if (formFooter)
        formFooter.classList.add('md-embedded-form--footer--succes')

      if (footerText) footerText.style.display = 'none'

      if (formBody) formBody.style.minHeight = 'initial'

      if (dynamicForm) dynamicForm.style.display = 'none'

      if (successMessage) successMessage.style.display = 'block'

      if (title) title.innerHTML = respData.title

      if (content) content.innerHTML = respData.content
    }
  } catch {
    if (submitButton) {
      submitButton.disabled = false
      submitButton.style.opacity = 1
    }

    const progressBar: Maybe<HTMLElement> = formWrap.querySelector(
      '[data-id="maildroppa-progressBar"]',
    )
    const formText: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-form--text"]',
    )

    if (progressBar) progressBar.style.display = 'none'

    if (formText) formText.style.display = 'none'
  }
}

const handleSubmitGDPR = async (
  e: Event,
  formDefinition: FormDefinition,
  formWrap: Maybe<HTMLDivElement>,
) => {
  e.preventDefault()
  if (!formWrap) {
    return null
  }

  //Getting fingerprint
  const fingerPrint = await getFingerPrint()

  const gdprForm: Maybe<HTMLDivElement> = formWrap.querySelector(
    '[data-id="maildroppa-wrap-gdprForm"]',
  )
  const dynamicForm: Maybe<HTMLDivElement> = formWrap.querySelector(
    '[data-id="maildroppa-dynamicForm"]',
  )
  const submitButton: Maybe<HTMLDivElement> = formWrap.querySelector(
    '[data-id="submit-button-gdpr"]',
  ) as HTMLButtonElement
  const valuesWithAgreement = {
    ...globalValues,
    agreement_id: formDefinition?.gdprAgreement?.id,
    finger_print: fingerPrint,
  }

  try {
    if (submitButton) {
      submitButton.disabled = true
      submitButton.style.opacity = 0.3
    }
    const canSubmit = allowSubmit(valuesWithAgreement)
    delete valuesWithAgreement.url
    const respData = canSubmit
      ? await submitFormService(valuesWithAgreement as SubmitFormServiceValues)
      : {
          title: 'Thanks for your subscription!',
          content:
            'We have sent you an email confirmation link. Please check your inbox to confirm your email address.',
        }

    if (respData.validationErrors) {
      displayErrors(respData.validationErrors, formDefinition.id)
      const progressBar: Maybe<HTMLDivElement> = formWrap.querySelector(
        '[data-id="maildroppa-progressBar"]',
      )
      const formText: Maybe<HTMLDivElement> = formWrap.querySelector(
        '[data-id="maildroppa-form--text"]',
      )
      const footerText: Maybe<HTMLDivElement> = formWrap.querySelector(
        '[data-id="maildroppa-form--footer-text"]',
      )

      if (dynamicForm) dynamicForm.style.display = 'block'

      if (progressBar) {
        progressBar.style.display = 'none'
      }

      if (formText) formText.style.display = 'none'
      if (gdprForm) gdprForm.style.display = 'none'
      if (footerText) footerText.style.display = 'block'
      submitButton.disabled = false
      submitButton.style.opacity = 1
      return
    }

    setLastSeenIfNecessary(formDefinition)

    const title: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-success-form--title"]',
    )
    const content: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-success-form--text"]',
    )

    const successMessage: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-SuccessMessage"]',
    )
    const formBody: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-form--body"]',
    )
    const formFooter: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-form--footer"]',
    )
    if (formFooter) formFooter.classList.add('md-embedded-form--footer--succes')
    if (successMessage) successMessage.style.display = 'block'
    if (formBody) formBody.style.minHeight = 'initial'
    if (gdprForm) gdprForm.style.display = 'none'
    if (title) title.innerHTML = respData.title
    if (content) content.innerHTML = respData.content
  } catch {
    if (submitButton) {
      submitButton.disabled = false
      submitButton.style.opacity = 1
    }
    const progressBar: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-progressBar"]',
    )
    const formText: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-form--text"]',
    )
    const footerText: Maybe<HTMLDivElement> = formWrap.querySelector(
      '[data-id="maildroppa-form--footer-text"]',
    )

    if (dynamicForm) dynamicForm.style.display = 'block'

    if (progressBar) {
      progressBar.style.display = 'none'
    }

    if (formText) formText.style.display = 'none'
    if (gdprForm) gdprForm.style.display = 'none'
    if (footerText) footerText.style.display = 'block'
  }
}

const progressBar = (
  formDefinition: FormDefinition,
  formWrap: HTMLDivElement,
) => {
  const progressBarProcent: Maybe<HTMLElement> = formWrap.querySelector(
    '[data-id="maildroppa-progressBar-procent"]',
  )
  const progressBarPart: Maybe<HTMLElement> = formWrap.querySelector(
    '[data-id="maildroppa-progressBar-part"]',
  )
  if (
    progressBarProcent &&
    formDefinition.jsonConfig.body.progressBarType == '50%'
  ) {
    progressBarProcent.style.display = 'block'
  }
  if (
    progressBarPart &&
    formDefinition.jsonConfig.body.progressBarType == '1/2'
  ) {
    progressBarPart.style.display = 'flex'
  }
}

const styleDropdownField = (form: HTMLDivElement, color: string) => {
  const select: HTMLInputElement | null = form.querySelector(
    '[data-id="maildroppa--select"]',
  )

  if (!select) return null
  select.addEventListener('change', () => {
    if (select.value != '') {
      select.style.color = color
    }
  })
}

const styleDateField = (form: HTMLDivElement, color: string) => {
  const date: HTMLInputElement | null = form.querySelector(
    '[data-id="maildroppa-data-input"]',
  )
  if (!date) return null

  date.addEventListener('change', () => {
    if (date.value != '') {
      date.style.color = color
    }
  })
}

const addAnimationClass = (
  formDefinition: FormDefinition,
  sliderBodyWrap: HTMLDivElement,
) => {
  const { jsonConfig } = formDefinition
  if (formDefinition.type === 'SLIDER') {
    if (jsonConfig.sliderPopupPosition === 'BOTTOM_RIGHT') {
      sliderBodyWrap.classList.add('animationBottom')
    }
    if (jsonConfig.sliderPopupPosition === 'BOTTOM_CENTER') {
      sliderBodyWrap.classList.add('animationBottom')
    }
    if (jsonConfig.sliderPopupPosition === 'BOTTOM_LEFT') {
      sliderBodyWrap.classList.add('animationBottom')
    }

    if (jsonConfig.sliderPopupPosition === 'CENTER_RIGHT') {
      sliderBodyWrap.classList.add('animationCenterRight')
    }
    if (jsonConfig.sliderPopupPosition === 'CENTER_LEFT') {
      sliderBodyWrap.classList.add('animationCenter')
    }
    if (jsonConfig.sliderPopupPosition === 'CENTER_RIGHT') {
      sliderBodyWrap.classList.add('animationCenter')
    }

    if (jsonConfig.sliderPopupPosition === 'TOP_LEFT') {
      sliderBodyWrap.classList.add('animationTop')
    }
    if (jsonConfig.sliderPopupPosition === 'TOP_CENTER') {
      sliderBodyWrap.classList.add('animationTop')
    }
    if (jsonConfig.sliderPopupPosition === 'TOP_RIGHT') {
      sliderBodyWrap.classList.add('animationTop')
    }
  }
}

const removeAnimationClass = (
  sliderBodyWrap: HTMLDivElement,
  sliderForm: HTMLDivElement,
) => {
  if (sliderBodyWrap.classList.contains('animationCenterRight')) {
    sliderBodyWrap.classList.remove('animationCenterRight')
    sliderBodyWrap.classList.add('reverseAnimationCenterRight')
    setTimeout(() => {
      sliderForm.classList.add('md-form--slider-open')
    }, 500)
  }
  if (sliderBodyWrap.classList.contains('animationCenter')) {
    sliderBodyWrap.classList.remove('animationCenter')
    sliderBodyWrap.classList.add('reverseAnimationCenter')
    setTimeout(() => {
      sliderForm.classList.add('md-form--slider-open')
    }, 500)
  }

  if (sliderBodyWrap.classList.contains('animationBottom')) {
    sliderBodyWrap.classList.remove('animationBottom')
    sliderBodyWrap.classList.add('reverseAnimationBottom')
    setTimeout(() => {
      sliderForm.classList.add('md-form--slider-open')
    }, 500)
  }

  if (sliderBodyWrap.classList.contains('animationTop')) {
    sliderBodyWrap.classList.remove('animationTop')
    sliderBodyWrap.classList.add('reverseAnimationTop')
    setTimeout(() => {
      sliderForm.classList.add('md-form--slider-open')
    }, 500)
  }
}

const sliderFormEmdeddedMode = (
  formWrap: Maybe<HTMLDivElement>,
  formDefinition: FormDefinition,
) => {
  if (!formWrap) return null
  const sliderForm: Maybe<HTMLDivElement> = formWrap.querySelector(
    `[data-id="maildroppa-formSliderEmdeddedMode-${formDefinition.id}"]`,
  )
  const sliderBody = formWrap.querySelector(
    `[data-id="maildroppa-sliderBody-${formDefinition.id}"]`,
  )
  const sliderBodyWrap: Maybe<HTMLDivElement> = formWrap.querySelector(
    `[data-id="maildroppa-getAnimationClass-${formDefinition.id}"]`,
  )

  if (sliderBodyWrap) addAnimationClass(formDefinition, sliderBodyWrap)

  sliderBody?.addEventListener('click', () => {
    if (sliderBodyWrap && sliderForm) {
      removeAnimationClass(sliderBodyWrap, sliderForm)
    }
  })
}

export const addEventListener = (
  formDefinition: FormDefinition,
  isEmbeddedMode: boolean,
) => {
  const formWrap = document.querySelector<HTMLDivElement>(
    `[data-id=maildroppa-form-${formDefinition.id}]`,
  )

  const popupContainer = document.querySelector<HTMLDivElement>(
    `.md-embedded-form-modal-popup-${formDefinition.id}`,
  )

  const popupBtn = document.querySelector<HTMLDivElement>(
    `#maildroppa-popup-btn-${formDefinition.id}`,
  )
  if (!formWrap) return null

  popupBtn?.addEventListener('click', () => {
    if (popupContainer) {
      popupContainer.style.display = 'block'
    }
    formWrap.style.display = 'block'
  })

  if (isEmbeddedMode) {
    if (formDefinition?.jsonConfig?.isExitIntentPopup) {
      document?.addEventListener('mouseleave', () => {
        if (!exceedsFrequencyRate(formDefinition)) {
          if (formWrap?.style) {
            formWrap.style.display = 'block'
          }
          if(popupContainer?.style) {
            popupContainer.style.display = 'block'
          }
          setLastSeenIfNecessary(formDefinition)
        }
      })
    }


    formWrap?.addEventListener('click', (e) => {
      if (
        e.target === formWrap.querySelector('[data-id="maildroppa-form--wrap"]')
      ) {
        formWrap.style.display = 'none'
      }
    })

    formWrap
      ?.querySelector('[data-id="maildroppa-form--close"]')
      ?.addEventListener('click', () => {
        formWrap.style.display = 'none'
        setLastSeenIfNecessary(formDefinition)
      })

    const sliderWrap: Maybe<HTMLDivElement> =
      formWrap.querySelector<HTMLDivElement>(
        `[data-id="maildroppa-formSliderEmdeddedMode-${formDefinition.id}"]`,
      )

    const handleCloseSlider = () => {
      console.log('handleCloseSlider')
      if (sliderWrap) {
        sliderWrap.style.display = 'none'
        setLastSeenIfNecessary(formDefinition)
      }
    }

    formWrap
      ?.querySelector('[data-id="maildroppa-slider-form--close"]')
      ?.addEventListener('click', handleCloseSlider)

    formWrap
      ?.querySelector('[data-id="maildroppa-slider-form-embedded--close"]')
      ?.addEventListener('click', handleCloseSlider)
  }

  progressBar(formDefinition, formWrap)

  styleDropdownField(
    formWrap,
    formDefinition.jsonConfig?.body?.inputFieldStyle?.color,
  )
  styleDateField(
    formWrap,
    formDefinition.jsonConfig?.body?.inputFieldStyle?.color,
  )

  if (isEmbeddedMode) sliderFormEmdeddedMode(formWrap, formDefinition)

  const dynamicForm = formWrap.querySelector(
    '[data-id="maildroppa-dynamicForm"]',
  )
  const gdprForm = formWrap?.querySelector(
    '[data-id="maildroppa-wrap-gdprForm"]',
  )

  dynamicForm?.addEventListener('submit', (e: Event) =>
    handleSubmit(e, formDefinition, formWrap),
  )

  gdprForm?.addEventListener('submit', (e: Event) =>
    handleSubmitGDPR(e, formDefinition, formWrap),
  )
}
