import { Fragment, h } from 'preact'

import { FormType } from '../types'
import { useAppContext } from '../hooks/useAppContext'
import { useFormVariant } from '../hooks/useFormVariant'

interface FormProps {
  children: h.JSX.Element
}

const FORM_VARIANT_CLASS_MAP: Record<FormType, string> = {
  SLIDER: 'slider',
  INLINE: 'default',
  POPUP: 'popup',
}

export const FormWrapper = ({ children }: FormProps) => {
  const { formDefinition } = useAppContext()

  const formVariant = useFormVariant()

  return (
    <Fragment>
      <div
        data-md-form={formDefinition.id}
        class={`
        md-form-overriden-${formDefinition.id}
        md-embedded-form md-embedded-form-${formDefinition.id}
        md-embedded-form--variant-${FORM_VARIANT_CLASS_MAP[formVariant]}`}
      >
        {children}
      </div>
    </Fragment>
  )
}
