import { h } from 'preact'

export const SuccessMessage = () => (
  <div
    data-id="maildroppa-SuccessMessage"
    class="md-embedded-form--text"
    style={{ display: 'none' }}
  >
    <strong
      data-id="maildroppa-success-form--title"
      class="md-embedded-form--title"
    />
    <p data-id="maildroppa-success-form--text" />
  </div>
)
