import { Fragment, h } from 'preact'

import { FormTypeWrapper } from './FormTypeWrapper'
import { LogoFooter } from './LogoFooter'
import { SliderBody } from './SliderBody'
import { borderPosition } from '../utils/borderPosition'
import { getAnimationClass } from '../utils/getAnimationClass'
import { useAppContext } from '../hooks/useAppContext'
import { useFormStyles } from '../utils/useFormStyles'

export const SliderFormEmdeddedMode = () => {
  const { formDefinition } = useAppContext()

  const formStyles = useFormStyles()

  return (
    <div>
      <div
        data-md-form={formDefinition.id}
        data-id={`maildroppa-formSliderEmdeddedMode-${formDefinition.id}`}
        class={`md-embedded-form
          md-embedded-form--variant-slider md-form--ready
          md-form-overriden-${formDefinition.id}
          md-embedded-form-${formDefinition.id}
          `}
        style={formStyles}
      >
        <div data-id={`maildroppa-getAnimationClass-${formDefinition.id}`}>
          <SliderBody />
        </div>

        <Fragment>
          <div
            class={`md-embedded-form--body ${getAnimationClass(
              formDefinition,
            )}`}
            style={borderPosition(formDefinition)}
          >
            <img
              src={`${process.env.PREACT_APP_HOST_URL}/assets/close-image.png`}
              alt="Close"
              class="md-embedded-form--close"
              data-id="maildroppa-slider-form-embedded--close"
            />

            <FormTypeWrapper />

            <LogoFooter />
          </div>
        </Fragment>
      </div>
    </div>
  )
}
