import { borderPosition } from '../utils/borderPosition'
import { h } from 'preact'
import { useAppContext } from '../hooks/useAppContext'

export const SliderBody = () => {
  const { formDefinition } = useAppContext()
  return (
    <div
      data-id={`maildroppa-sliderBody-${formDefinition.id}`}
      class="md-embedded-form--slider-body"
      style={borderPosition(formDefinition)}
    >
      <div>
        <img
          src={`${process.env.PREACT_APP_HOST_URL}/assets/close-image.png`}
          alt="Close"
          class="md-embedded-form--close"
          data-id="maildroppa-slider-form--close"
        />
        <p class="md-embedded-form--text-slider">
          {formDefinition.jsonConfig.body.text.value}
        </p>
      </div>
    </div>
  )
}
