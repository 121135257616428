import { FormFieldDefintion } from '../types'
import { h } from 'preact'

export const CheckboxField = ({ fieldDefintion }: FormFieldDefintion) => {
  const optionValuesLength = (index: number) =>
    fieldDefintion?.optionValues?.length || 0 > 1 ? `[${index}]` : ''

  return (
    <div class="md-embedded-form--checkbox-wrapper">
      {fieldDefintion.label && (
        <div class="md-embedded-form--input-title">{fieldDefintion.label}</div>
      )}

      {fieldDefintion?.optionValues?.map((item, index) => (
        <label class="md-embedded-form--checkbox" key={item}>
          <input
            value={item}
            name={`fields[${
              fieldDefintion.indexPosition
            }].value${optionValuesLength(index)}`}
            class="md-embedded-form--checkbox-input"
            tabIndex={fieldDefintion.indexPosition}
            type="checkbox"
          />
          {item}
        </label>
      ))}
      <input
        value={fieldDefintion.name}
        name={`fields[${fieldDefintion.indexPosition}].name`}
        type="hidden"
      />
    </div>
  )
}
