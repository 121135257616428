import { AppContext } from '../context/AppContext'
import { Form } from './Form'
import type { FormDefinition } from '../types'
import { SelfScriptLinks } from './SelfScriptLinks'
import { h } from 'preact'

export interface AppProps {
  isEmdeddedMode: boolean
  formDefinition: FormDefinition
  includeSelfScript?: boolean
}

/**
 * React app entry point. This can only produce static HTML!!
 * See `event-listeners.ts` for the details.
 */
export const App = ({
  isEmdeddedMode,
  formDefinition,
  includeSelfScript,
}: AppProps) => (
  <div>
    <AppContext.Provider value={{ isEmdeddedMode, formDefinition }}>
      {includeSelfScript ? <SelfScriptLinks /> : null}
      <Form />
    </AppContext.Provider>
  </div>
)
