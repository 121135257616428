type GenericObject = Record<string, string | number | boolean | string[]>
export const compareBy =
  (field: string) =>
  (a: GenericObject, b: GenericObject): 1 | -1 | 0 => {
    if (a[field] < b[field]) {
      return -1
    }
    if (a[field] > b[field]) {
      return 1
    }
    return 0
  }
