import { FormDefinition } from '../types'
import { h } from 'preact'

export const getSliderPosition = (
  formDefinition: FormDefinition,
): h.JSX.CSSProperties | string => {
  const { jsonConfig } = formDefinition

  const isMobile = window.innerWidth
    ? window.innerWidth < 500
    : window.screen.width < 500

  if (isMobile && jsonConfig.sliderPopupPosition.includes('TOP')) {
    return 'bottom: unset; right: 0; top: 0; border-radius: 0 0 10px 10px; margin: 0; z-index: 10001;'
  }
  if (isMobile) {
    return 'bottom: 0; right: 0; border-radius: 10px 10px 0 0; margin: 0; z-index: 10001;'
  }

  switch (jsonConfig.sliderPopupPosition) {
    case 'BOTTOM_RIGHT':
      return 'bottom: 0; right: 40px; border-radius: 10px 10px 0 0; margin: 0; z-index: 10001;'

    case 'CENTER_RIGHT':
      return 'bottom: 50vh; right: 0; border-radius: 10px 0 0 10px; margin: 0; z-index: 10001; transform: translateY(50%);'

    case 'TOP_RIGHT':
      return 'bottom: unset; right: 40px; top: 0; border-radius: 0 0 10px 10px; margin: 0; z-index: 10001;'

    case 'TOP_LEFT':
      return 'bottom: unset;right: unset; left: 40px;top: 0;border-radius: 0 0 10px 10px; margin: 0; z-index: 10001;'

    case 'TOP_CENTER':
      return 'bottom: unset; right: calc(100vw / 2 - 200px); top: 0; border-radius: 0 0 10px 10px; margin: 0; z-index: 10001;'

    case 'CENTER_LEFT':
      return 'bottom: 50vh; right: unset; left: 0; border-radius: 0 10px 10px 0; margin: 0; z-index: 10001; transform: translateY(50%);'

    case 'BOTTOM_LEFT':
      return 'bottom: 0; right: unset; left: 40px; border-radius: 10px 10px 0 0; margin: 0; z-index: 10001;'

    case 'BOTTOM_CENTER':
      return 'bottom: 0; right: calc(100vw / 2 - 200px); margin: 0; border-radius: 10px 10px 0 0; margin: 0; z-index: 10001;'
    default:
      return 'bottom: 0; right: calc(100vw / 2 - 200px); margin: 0; border-radius: 10px 10px 0 0; margin: 0; z-index: 10001;'
  }
}
