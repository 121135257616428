import { FormFieldDefintion } from '../types'
import { h } from 'preact'

export const RadioField = ({ fieldDefintion }: FormFieldDefintion) => (
  <div class="md-embedded-form--checkbox-wrapper">
    {fieldDefintion.label && (
      <div class="md-embedded-form--input-title">{fieldDefintion.label}</div>
    )}

    <input
      value={fieldDefintion.name}
      name={`fields[${fieldDefintion.indexPosition}].name`}
      type="hidden"
    />
    {fieldDefintion?.optionValues?.map((item) => (
      <label key={item} class="md-embedded-form--checkbox">
        <input
          value={item}
          name={`fields[${fieldDefintion.indexPosition}].value`}
          tabIndex={fieldDefintion.indexPosition}
          type="radio"
          required={fieldDefintion.isRequired}
        />
        {item}
      </label>
    ))}
  </div>
)
