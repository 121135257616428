/**
 * This file mounts form and adds event listeners for already rendered forms
 *
 * There are 3 contexts for this scripts:
 * 1. Hosted form on maildroppa domain. Renders React app and adds event listeners
 * 2. Embdedded script.Renders React app to mounting point.
 *    a. Slider and popup to the document root
 *    b. Inline to before the script placement
 * 3. HTML-prerendered script. Only adds event listeners.
 */

import { h, render } from 'preact'

import { App } from './components/app'
import { FormDefinition } from './types'
import { addEventListener } from './events/event-listeners'
import { exceedsFrequencyRate } from './utils/form-frequency'
import { getFingerPrint } from './utils/getFingerPrint'
import { loadFormDefinition } from './utils/loadFormDefinition'
import { renderToString } from 'preact-render-to-string'

const mountForms = async () => {
  localStorage.setItem('entryTime', new Date().toString())

  const devMode = process.env.NODE_ENV === 'development'
  const placedOnFormMaildroppa = document.location.origin.includes(
    'form.maildroppa.com',
  )
  const runLocallyForDev =
    devMode &&
    (document.location.origin.includes('localhost:8080') ||
      document.location.origin.includes('localhost:3000'))

  if (!devMode) {
    await loadStyles()
  }

  const isEmbeddedOnCustomerSite = !placedOnFormMaildroppa && !runLocallyForDev

  const sharedFormId = isEmbeddedOnCustomerSite
    ? null
    : new URL(document.location.href).searchParams.get('id')

  const formNodes = document.querySelectorAll<HTMLElement>('[data-md-form]')

  if (formNodes.length === 0 && !sharedFormId) {
    return
  }

  const formIds = [...formNodes].map((item) => item.dataset.mdForm as string)

  if (!formIds.length && sharedFormId) {
    formIds.push(sharedFormId)
  }
  const fingerPrint = await getFingerPrint()

  formIds.forEach(async (formId) => {
    const formDefinition = await loadFormDefinition(formId, fingerPrint)

    if (!formDefinition) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('Could not find a form definition')
        return
      }

      if (!isEmbeddedOnCustomerSite) {
        window.location.replace('https://maildroppa.com/404.html')
      }
      return
    }

    if (exceedsFrequencyRate(formDefinition) && isEmbeddedOnCustomerSite
      && !formDefinition.jsonConfig.isExitIntentPopup) {
      return
    }

    const mountingPoint =
      formDefinition.type === 'INLINE' && !sharedFormId ? 'inline' : 'root'

    if (mountingPoint === 'root') {
      const root = document.getElementsByTagName('body')[0]

      if (!root) {
        throw new Error('Not #root element found')
      }

      if (!document.querySelector(`[data-id="maildroppa-form-${formId}"]`)) {
        const container = document.createElement('div')
        const millisecondsInSecond = 1000
        const renderDelayInMilliseconds = isEmbeddedOnCustomerSite && !formDefinition.jsonConfig.isExitIntentPopup
          ? (formDefinition.jsonConfig.delayTime || 0) * millisecondsInSecond
          : 0

        setTimeout(() => {
          render(
            <App
              isEmdeddedMode={isEmbeddedOnCustomerSite}
              formDefinition={formDefinition}
            />,
            container,
          )
          root.appendChild(container)
          addEventListener(formDefinition, isEmbeddedOnCustomerSite)
        }, renderDelayInMilliseconds)
      } else {
        addEventListener(formDefinition, isEmbeddedOnCustomerSite)
      }
    } else {
      const selector = `[data-md-form="${formId}"]`
      const self = document.querySelector(selector)
      const container = document.createElement('div')
      if (!self?.parentNode) {
        throw new Error(`Not ${selector} element found`)
      }

      self.parentNode.insertBefore(container, self)
      if (!document.querySelector(`[data-id="maildroppa-form-${formId}"]`)) {
        render(
          <App
            isEmdeddedMode={isEmbeddedOnCustomerSite}
            formDefinition={formDefinition}
          />,
          container,
        )
      }

      addEventListener(formDefinition, isEmbeddedOnCustomerSite)
    }
  })
}

const loadStyles = (): Promise<void> =>
  new Promise((resolve, reject) => {
    const link: HTMLLinkElement = document.createElement('link')
    link.href = `${process.env.PREACT_APP_HOST_URL}/index.css`
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.media = 'screen,print'

    link.onload = () => resolve()
    link.onerror = () => reject(new Error('Failed to load form styles'))

    document.getElementsByTagName('head')[0].appendChild(link)
  })

if (
  document.readyState === 'interactive' ||
  document.readyState === 'complete'
) {
  mountForms()
} else {
  window.addEventListener('DOMContentLoaded', mountForms)
}

// Expose public API
window.__MD__RENDER_TO_STRING = (formDefinition: FormDefinition): string =>
  renderToString(
    <App
      isEmdeddedMode={true}
      formDefinition={formDefinition}
      includeSelfScript
    />,
  )
