import type { FormDefinition } from '../types'
import { h } from 'preact'

interface ProgressBarConfig {
  formDefinition: FormDefinition
}

export const ProgressBar = ({ formDefinition }: ProgressBarConfig) => {
  const { body } = formDefinition.jsonConfig
  if (!body.progressBarType || body.progressBarType === 'None') return null

  return (
    <div data-id="maildroppa-progressBar">
      <div
        data-id="maildroppa-progressBar-procent"
        class="md-embedded-form--progress-bar-wrapper"
        style={{ display: 'none' }}
      >
        <p class="md-embedded-form--progress-bar-percent-text">
          {body.progressBarPercentStyle?.text.value}
        </p>
        <div class="md-embedded-form--progress-bar-percent-bar-container">
          <div
            class="md-embedded-form--progress-bar-percent-bar"
            style={`background-color: ${body.progressBarPercentStyle?.backgroundColor}`}
          />
        </div>
      </div>

      <div
        data-id="maildroppa-progressBar-part"
        class="md-embedded-form--progress-bar-wrapper md-embedded-form--progress-bar-steps-wrapper"
        style={{ display: 'none' }}
      >
        <div
          class="md-embedded-form--progress-bar-step-one-container"
          style={`background-color: ${body.progressBarStepStyle?.backgroundColorActive}`}
        >
          <span class="md-embedded-form--progress-bar-step-indicator">1</span>
          {body.progressBarStepStyle?.text.stepOneValue}
        </div>
        <div
          class="md-embedded-form--progress-bar-step-two-container"
          style={`background-color: ${body.progressBarStepStyle?.backgroundColorInactive}`}
        >
          <span class="md-embedded-form--progress-bar-step-indicator">2</span>
          {body.progressBarStepStyle?.text.stepTwoValue}
        </div>
      </div>
    </div>
  )
}
