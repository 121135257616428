import { Fragment, h } from 'preact'

import { FormTypeWrapper } from './FormTypeWrapper'
import { FormWrapper } from './FormWrapper'
import { LogoFooter } from './LogoFooter'
import { useAppContext } from '../hooks/useAppContext'

export const SliderFormPreview = () => {
  const { formDefinition } = useAppContext()

  return (
    <div class={`md-embedded-form-modal-popup-${formDefinition.id} `}>
      <FormWrapper>
        <Fragment>
          <div
            class="md-embedded-form--body md-embedded-form--body "
            data-id="maildroppa-form--body"
          >
            <FormTypeWrapper />
            <LogoFooter />
          </div>
        </Fragment>
      </FormWrapper>
    </div>
  )
}
