import { FormInputField, FormInputFieldDataType } from '../types'
import { FunctionComponent, h } from 'preact'

import { CheckboxField } from './CheckboxField'
import { DateField } from './DateField'
import { DropdownField } from './DropdownField'
import { RadioField } from './RadioField'
import { TextAreaField } from './TextAreaField'
import { TextField } from './TextField'

interface GenerateFormField {
  fieldDefintion: FormInputField
}
const fields: Record<
  FormInputFieldDataType,
  FunctionComponent<{ fieldDefintion: FormInputField }>
> = {
  TEXT: TextField,
  RADIO: RadioField,
  DROPDOWN: DropdownField,
  TEXTAREA: TextAreaField,
  CHECKBOX: CheckboxField,
  NUMBER: TextField,
  DATE: DateField,
}
export const Field = ({ fieldDefintion }: GenerateFormField) =>
  h(fields[fieldDefintion.dataType], { fieldDefintion })
