import { Fragment, h } from 'preact'

import type { FormColorHex } from '../types'
import { useAppContext } from '../hooks/useAppContext'

export const LogoFooter = () => {
  const {
    formDefinition: { jsonConfig, premium },
  } = useAppContext()

  const getColorShade = (color: string | FormColorHex, amt: number) => {
    let col = color.replace(/^#/, '')
    if (col.length === 3)
      col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [rSource, gSource, bSource] = col.match(/.{2}/g) || []
    let [r, g, b]: Array<string | number> = [
      parseInt(rSource || '0', 16) + amt,
      parseInt(gSource || '0', 16) + amt,
      parseInt(bSource || '0', 16) + amt,
    ]

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `${rr}${gg}${bb}`
  }

  const getFooterBackground = () => {
    const parsedBgColor = jsonConfig.mainBackgroundColor.replace('#', '')

    const c = getColorShade(parsedBgColor, -10)
    const rgb = parseInt(parsedBgColor, 16)
    const r = (rgb >> 16) & 0xff
    const g = (rgb >> 8) & 0xff
    const b = (rgb >> 0) & 0xff

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b

    const decimal = parseInt(c, 16)
    let hexStr = decimal.toString(16)

    if (
      luma < 20 ||
      hexStr.startsWith('fff') ||
      luma > 240 ||
      (r < 133 && b > 230)
    ) {
      hexStr = '2B3254'
    }
    return `#${hexStr.length < 5 ? `00${hexStr}` : hexStr}`
  }

  return (
    <Fragment>
      <p
        data-id="maildroppa-form--footer-text"
        class="md-embedded-form--footer-text"
      >
        {jsonConfig.footer.text.value}
      </p>
      {!premium && (
        <div
          data-id="maildroppa-form--footer"
          class="md-embedded-form--footer"
          style={{
            'background-color': getFooterBackground(),
            'padding': '8px 0',
          }}
        >
          <a
            class="md-embedded-form--logo-wrapper"
            href="https://maildroppa.com/"
            target="_blank"
            rel="noopener noreferrer"
            title="Click to learn more about Maildroppa"
          >
            <div class="md-embedded-form--branding-text">POWERED BY</div>
            <svg
              width="126"
              height="18"
              viewBox="0 0 126 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.1787 5.81275H30.9656V5.99854C31.523 5.75082 32.1423 5.62695 32.8236 5.62695C34.1241 5.62695 35.2389 6.1224 36.1059 6.98944C36.973 6.18433 38.0877 5.62695 39.3883 5.62695C41.9275 5.62695 44.0331 7.67069 44.0331 10.2718V14.5451H41.2462V10.2718C41.2462 9.21897 40.3792 8.41386 39.3883 8.41386C38.3354 8.41386 37.5303 9.21897 37.5303 10.2718V14.5451H34.6815V10.2718C34.6815 9.21897 33.8145 8.41386 32.8236 8.41386C31.8327 8.41386 30.9656 9.21897 30.9656 10.2718V14.5451H28.1787V5.81275Z"
                fill="white"
              />
              <path
                d="M52.0222 14.4831C51.4029 14.7309 50.7836 14.9167 50.1024 14.9167C47.5632 14.9167 45.4575 12.8729 45.4575 10.2718C45.4575 7.73262 47.5013 5.62695 50.1024 5.62695C50.7836 5.62695 51.4029 5.75082 52.0222 5.99854V5.87468H54.8091V14.5451L52.0222 14.4831ZM48.2444 10.2718C48.2444 11.3246 49.1115 12.1297 50.1024 12.1297C51.1552 12.1297 51.9603 11.3246 51.9603 10.2718C51.9603 9.21897 51.0933 8.41386 50.1024 8.41386C49.1115 8.41386 48.2444 9.21897 48.2444 10.2718Z"
                fill="white"
              />
              <path
                d="M56.1097 2.59199C56.1097 1.4153 57.0387 0.486328 58.2773 0.486328C59.454 0.486328 60.383 1.4153 60.383 2.59199C60.383 3.76869 59.454 4.69766 58.2773 4.69766C57.1006 4.69766 56.1097 3.76869 56.1097 2.59199ZM59.6398 14.4828H56.8529V5.81242H59.6398V14.4828Z"
                fill="white"
              />
              <path
                d="M64.8421 14.4826H62.0552V2.5918H64.8421V14.4826Z"
                fill="white"
              />
              <path
                d="M72.7693 14.4812C72.2119 14.7289 71.5926 14.8528 70.9113 14.8528C68.3721 14.8528 66.2665 12.8091 66.2665 10.208C66.2665 7.66878 68.3721 5.56312 70.9113 5.56312C71.5926 5.56312 72.2119 5.74891 72.7693 5.9347V2.65234H75.5562V14.5432L72.7693 14.4812ZM69.0534 10.208C69.0534 11.2608 69.8585 12.0659 70.9113 12.0659C71.9642 12.0659 72.7693 11.2608 72.7693 10.208C72.7693 9.15513 71.9642 8.35002 70.9113 8.35002C69.8585 8.35002 69.0534 9.15513 69.0534 10.208Z"
                fill="white"
              />
              <path
                d="M77.3522 5.81413H80.1391V5.93799C80.6965 5.69027 81.3158 5.56641 81.9971 5.56641C83.1737 5.56641 84.2885 5.99993 85.1556 6.80503L83.2357 8.84877C82.8641 8.53911 82.4306 8.35331 81.9351 8.35331C80.8823 8.35331 80.0772 9.15842 80.0772 10.2113V14.4845H77.2903V5.81413H77.3522Z"
                fill="white"
              />
              <path
                d="M89.3668 5.50195C91.906 5.50195 94.0117 7.60762 94.0117 10.1468C94.0117 12.686 91.9679 14.7917 89.3668 14.7917C86.8276 14.7917 84.722 12.7479 84.722 10.1468C84.722 7.54569 86.8276 5.50195 89.3668 5.50195ZM89.3668 8.28886C88.314 8.28886 87.5089 9.09397 87.5089 10.1468C87.5089 11.1996 88.314 12.0047 89.3668 12.0047C90.4197 12.0047 91.2248 11.1377 91.2248 10.1468C91.2248 9.1559 90.4197 8.28886 89.3668 8.28886Z"
                fill="white"
              />
              <path
                d="M95.2504 5.68775H98.0373V5.87354C98.5947 5.62582 99.214 5.50195 99.8952 5.50195C102.434 5.50195 104.54 7.60762 104.54 10.1468C104.54 12.686 102.496 14.7916 99.8952 14.7916C99.214 14.7916 98.5947 14.6678 98.0373 14.4201V17.7644H95.2504V5.68775ZM97.9753 10.0849C97.9753 11.1377 98.8424 11.9428 99.8333 11.9428C100.824 11.9428 101.691 11.0758 101.691 10.0849C101.691 9.09397 100.824 8.22693 99.8333 8.22693C98.8424 8.22693 97.9753 9.09397 97.9753 10.0849Z"
                fill="white"
              />
              <path
                d="M105.717 5.68775H108.504V5.87354C109.061 5.62582 109.68 5.50195 110.362 5.50195C112.901 5.50195 115.006 7.60762 115.006 10.1468C115.006 12.686 112.963 14.7916 110.362 14.7916C109.68 14.7916 109.061 14.6678 108.504 14.4201V17.7644H105.717V5.68775ZM108.442 10.0849C108.442 11.1377 109.309 11.9428 110.3 11.9428C111.291 11.9428 112.158 11.0758 112.158 10.0849C112.158 9.09397 111.291 8.22693 110.3 8.22693C109.309 8.22693 108.442 9.09397 108.442 10.0849Z"
                fill="white"
              />
              <path
                d="M122.376 14.4845C121.819 14.7322 121.2 14.8561 120.456 14.8561C117.917 14.8561 115.812 12.8124 115.812 10.2113C115.812 7.67207 117.855 5.56641 120.456 5.56641C121.138 5.56641 121.757 5.7522 122.376 5.93799V5.87606H125.163V14.5464L122.376 14.4845ZM118.598 10.2732C118.598 11.326 119.465 12.1311 120.456 12.1311C121.509 12.1311 122.314 11.326 122.314 10.2732C122.314 9.22035 121.447 8.41525 120.456 8.41525C119.465 8.41525 118.598 9.22035 118.598 10.2732Z"
                fill="white"
              />
              <path
                d="M11.0237 13.1683C10.9618 12.9825 10.8379 12.8586 10.6521 12.7967L7.86521 11.62C7.67942 11.5581 7.80328 11.62 7.61749 11.7439C7.43169 11.8677 6.81238 12.6109 6.81238 12.549V17.4415C6.81238 17.6893 6.99817 17.937 7.2459 17.9989C7.30783 17.9989 7.36976 17.9989 7.43169 17.9989C7.61748 17.9989 7.80328 17.937 7.92714 17.7512L10.9618 13.6637C11.0856 13.5399 11.0856 13.3541 11.0237 13.1683Z"
                fill="#1B8FDD"
              />
              <path
                d="M18.5794 0.102611C18.3936 -0.0212515 18.1459 -0.0212515 17.9601 0.0406799L0.309657 9.26845C0.123863 9.39231 0 9.64003 0 9.88776C0 10.1355 0.185794 10.3213 0.371588 10.3832L5.32609 12.0554L16.3499 2.82759L6.87438 12.5508L15.9164 15.6474C15.9783 15.6474 16.0402 15.7093 16.1021 15.7093C16.226 15.7093 16.2879 15.7093 16.4118 15.6474C16.5357 15.5854 16.6595 15.3996 16.6595 15.2139L18.8271 0.721924C18.8271 0.474199 18.7652 0.226474 18.5794 0.102611Z"
                fill="#4EB9F4"
              />
            </svg>
          </a>
        </div>
      )}
    </Fragment>
  )
}
