import { FormDefinition } from '../types'
import { getFrequencyRate } from './formDefinition'

export const exceedsFrequencyRate = (
  formDefinition: FormDefinition,
): boolean => {
  const frequencyRateInHours = getFrequencyRate(formDefinition)

  if (!frequencyRateInHours) {
    return false
  }

  const lastSeenDate = getLastSeen(formDefinition.id)

  if (!lastSeenDate) {
    return false
  }

  const timeNow = new Date()
  const lastSeen = new Date(lastSeenDate)
  const hoursSinceLastSeen = getDifferenceInHours(timeNow, lastSeen)
  return frequencyRateInHours > hoursSinceLastSeen
}

const getDifferenceInHours = (date1: Date, date2: Date): number => {
  const millisecondsInHour = 1000 * 60 * 60
  return Math.abs(date1.valueOf() - date2.valueOf()) / millisecondsInHour
}

const createLastSeenKey = (formId: string): string => `lastSeen${formId}`

export const setLastSeen = (formId: string): void => {
  localStorage.setItem(createLastSeenKey(formId), new Date().toString())
}

const getLastSeen = (formId: string): string | null =>
  localStorage.getItem(createLastSeenKey(formId))

export const setLastSeenIfNecessary = (formDefinition: FormDefinition) => {
  if (getFrequencyRate(formDefinition)) {
    setLastSeen(formDefinition.id)
  }
}
