import { Fragment, h } from 'preact'

import { SliderFormEmdeddedMode } from './SliderFormEmdeddedMode'
import { SliderFormPreview } from './SliderFormPreview'
import { useAppContext } from '../hooks/useAppContext'

export const SliderForm = () => {
  const { isEmdeddedMode } = useAppContext()

  return (
    <Fragment>
      {isEmdeddedMode ? <SliderFormEmdeddedMode /> : <SliderFormPreview />}
    </Fragment>
  )
}
