import { FormDefinition } from '../types'

export const getAnimationClass = (formDefinition: FormDefinition) => {
  const { jsonConfig } = formDefinition

  if (jsonConfig.sliderPopupPosition === 'CENTER_RIGHT') {
    return 'animationCenterRight'
  }

  if (jsonConfig.sliderPopupPosition.includes('TOP')) {
    return 'animationTop'
  }

  if (jsonConfig.sliderPopupPosition.includes('BOTTOM')) {
    return 'animationBottom'
  }

  if (jsonConfig.sliderPopupPosition.includes('CENTER')) {
    return 'animationCenter'
  }
}
