import { FormType } from '../types'
import { useAppContext } from './useAppContext'
import { useMemo } from 'preact/hooks'

export const useFormVariant = (): FormType => {
  const { isEmdeddedMode, formDefinition } = useAppContext()
  return useMemo<FormType>(() => {
    if (formDefinition.type === 'SLIDER' && !isEmdeddedMode) {
      return 'POPUP'
    }

    if (formDefinition.type === 'DEFAULT') {
      return 'POPUP'
    }

    return formDefinition.type
  }, [formDefinition.type, isEmdeddedMode])
}
