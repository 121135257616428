import { Fragment, h } from 'preact'

import { FormTypeWrapper } from './FormTypeWrapper'
import { FormWrapper } from './FormWrapper'
import { LogoFooter } from './LogoFooter'
import { useAppContext } from '../hooks/useAppContext'

export const PopupForm = () => {
  const { formDefinition, isEmdeddedMode } = useAppContext()

  return (
    <Fragment>
      <div
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          zIndex: 1001,
          overflow: 'auto',
          top: 0,
          left: 0,
          display:
            (formDefinition.jsonConfig.isPopupWidget && isEmdeddedMode)
            || (formDefinition.jsonConfig.isExitIntentPopup && isEmdeddedMode)
              ? 'none'
              : 'block',
          backgroundColor: isEmdeddedMode
            ? 'rgba(0, 0, 0, 0.5)'
            : 'transparent',
        }}
        class={`md-embedded-form-modal-popup-${formDefinition.id}`}
        data-id="maildroppa-form--wrap"
      >
        <FormWrapper>
          <div
            class={`md-embedded-form--body ${
              isEmdeddedMode ? 'md-embedded-form--popup-animation' : ''
            }`}
            data-id="maildroppa-form--body"
          >
            {isEmdeddedMode ? (
              <img
                src={`${process.env.PREACT_APP_HOST_URL}/assets/close-image.png`}
                alt="Close"
                class="md-embedded-form--close"
                data-id="maildroppa-form--close"
              />
            ) : null}

            <FormTypeWrapper />

            <LogoFooter />
          </div>
        </FormWrapper>
      </div>
    </Fragment>
  )
}
