import { FormInputField } from '../types'
import { h } from 'preact'
import { hexToRgbA } from '../utils/hexToRgba'
import { useAppContext } from '../hooks/useAppContext'

interface FieldDefintion {
  fieldDefintion: FormInputField
}

export const DateField = ({ fieldDefintion }: FieldDefintion) => {
  const { formDefinition } = useAppContext()
  return (
    <label class="md-embedded-form--input-wrapper">
      {fieldDefintion?.label && (
        <div class="md-embedded-form--input-title">{fieldDefintion.label}</div>
      )}
      <div class="md-embedded-form--input">
        <input
          data-id="maildroppa-data-input"
          name={`fields[${fieldDefintion?.indexPosition}].value`}
          class="md-embedded-form--input-el"
          placeholder={`${
            fieldDefintion?.placeholder || fieldDefintion?.label
          }`}
          tabIndex={fieldDefintion?.indexPosition}
          required={fieldDefintion?.isRequired}
          type={fieldDefintion?.dataType}
          style={{
            color: hexToRgbA(
              formDefinition.jsonConfig?.body?.inputFieldStyle?.color,
              0.4,
            ),
          }}
        />
        <input
          value={`${fieldDefintion?.name}`}
          name={`fields[${fieldDefintion?.indexPosition}].name`}
          type="hidden"
        />
      </div>
    </label>
  )
}
