import { Field } from './Field'
import { ProgressBar } from './ProgressBar'
import { compareBy } from '../utils/compare'
import { h } from 'preact'
import { useAppContext } from '../hooks/useAppContext'
import { useMemo } from 'preact/hooks'

export const DynamicFormFields = () => {
  const { formDefinition } = useAppContext()

  const sortedInputs = useMemo(
    () => formDefinition.inputFields.sort(compareBy('indexPosition')),
    [formDefinition],
  )

  return (
    <div data-id="maildroppa-dynamicForm">
      <ProgressBar formDefinition={formDefinition} />
      <strong class="md-embedded-form--title">
        {formDefinition.jsonConfig.header.text.value}
      </strong>

      <p data-id="maildroppa-form--text" class="md-embedded-form--text">
        {formDefinition.jsonConfig.body.text.value}
      </p>

      <form
        method="post"
        action={`${process.env.PREACT_APP_BACKEND_URL}/subscribe`}
        class={`md-embedded-form--form-${formDefinition.id}`}
        id={`mainForm-${formDefinition.id}`}
      >
        {sortedInputs.map((fieldDefintion) => (
          <div key={fieldDefintion.name + fieldDefintion.placeholder}>
            <Field fieldDefintion={fieldDefintion} />
            <div
              class="md-embedded-form--error"
              data-error-wrapper={`error-${fieldDefintion.name}-${formDefinition.id}`}
              style="display: none;"
            />
          </div>
        ))}
        <input
          class="md-embedded-form--input-el"
          type="text"
          name="url"
          id="url"
          style="z-index: -1; position: absolute; opacity: 0;"
          tabindex="-1"
        />

        <div
          class="md-embedded-form--btn-wrapper"
          style="display: flex; justify-content: center;"
        >
          <button
            type="submit"
            class="md-embedded-form--btn"
            data-id="submit-button"
            form={`mainForm-${formDefinition.id}`}
          >
            {formDefinition.jsonConfig.button.text.value}
          </button>
        </div>
        <p
          class=" md-embedded-form--subscriber-counter--text"
          style="margin-bottom: 20px; border: 0;"
        >
          {formDefinition.subscriberCount}{' '}
          {formDefinition.jsonConfig.body.subscriberCounterStyle?.text.value}
        </p>
        <input name="form_id" value={formDefinition.id} type="hidden" />
      </form>
    </div>
  )
}
