import type { FormDefinition, Maybe } from '../types'

export const loadFormDefinition = async (formId: string, fingerPrint: string): Promise<Maybe<FormDefinition>> => {
  const response = await fetch(
    `${process.env.PREACT_APP_BACKEND_URL}/form/${formId}?fingerPrint=${fingerPrint}`,
  )

  if (response.status !== 200) {
    return null
  }

  return response.json()
}
