import { h } from 'preact'
import { useAppContext } from '../hooks/useAppContext'
import { useFormVariant } from '../hooks/useFormVariant'

export const GdprForm = () => {
  const formVariant = useFormVariant()
  const { formDefinition, premium } = useAppContext()

  return (
    <div data-id="maildroppa-wrap-gdprForm" style={{ display: 'none' }}>
      <strong class="md-embedded-form--title">
        {formDefinition.jsonConfig.header.text.value}
      </strong>
      <form
        method="post"
        action={`${process.env.PREACT_APP_BACKEND_URL}/subscribe`}
        id={
          formVariant === 'POPUP'
            ? `gdprFormPopup-${formDefinition.id}`
            : `gdprForm-${formDefinition.id}`
        }
      >
        <input name="form_id" value={formDefinition.id} type="hidden" />
        <div class="md-embedded-form--checkbox-wrapper">
          <label class="md-embedded-form--checkbox">
            <input
              name="gdprAgreement"
              value="agree"
              type="checkbox"
              required
              checked
            />
            <div
              style="flex:1;"
              dangerouslySetInnerHTML={{
                __html: formDefinition?.gdprAgreement?.agreement || '',
              }}
            />
          </label>
        </div>
        <div
          class="md-embedded-form--btn-wrapper"
          style={`display: flex; justify-content: center;${
            !premium && 'margin-bottom: 85px;'
          }`}
        >
          <button
            type="submit"
            class="md-embedded-form--btn"
            data-id="submit-button-gdpr"
            form={
              formVariant === 'POPUP'
                ? `gdprFormPopup-${formDefinition.id}`
                : `gdprForm-${formDefinition.id}`
            }
            id={`gdprAgreeButton-${formDefinition.id}`}
          >
            Agree
          </button>
        </div>
      </form>
    </div>
  )
}
