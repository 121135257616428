import { FormDefinition } from '../types'
import { hexToRgbA } from './hexToRgba'
import { mapStyleToTextCss } from './mapStyleToTextCss'
import { propStyle } from './propStyle'

export const generateCSSOverride = (formDefinition: FormDefinition) => {
  const { jsonConfig, premium } = formDefinition

  const css = `
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--body {
        ${propStyle('border-radius', jsonConfig.mainBorder?.radius, 'px')}
        ${propStyle('border-color', jsonConfig.mainBorder?.color)}
        ${propStyle('border-width', jsonConfig.mainBorder?.size, 'px')}
        ${propStyle('border-style', 'solid')}
        ${propStyle('background', jsonConfig.mainBackgroundColor)}
      }
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--slider-body {
        ${propStyle('border-radius', jsonConfig.mainBorder?.radius, 'px')}
        ${propStyle('border-color', jsonConfig.mainBorder?.color)}
        ${propStyle('border-width', jsonConfig.mainBorder?.size, 'px')}
        ${propStyle('border-style', 'solid')}
        ${propStyle('background', jsonConfig.mainBackgroundColor)}
      }
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--title {
        ${mapStyleToTextCss(jsonConfig.header.text, true)}
        ${propStyle('font-family', jsonConfig?.mainFontFamily, '', true)}
        ${propStyle('text-align', jsonConfig.header.text.textAlign)}
      }
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--text {
        ${mapStyleToTextCss(jsonConfig.body.text, true)}
        ${propStyle('font-family', jsonConfig?.mainFontFamily, '', true)}
        ${propStyle('text-align', jsonConfig.body.text.textAlign)}
      }
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--text-slider {
        ${mapStyleToTextCss(jsonConfig.body.text, true)}
        ${propStyle('font-family', jsonConfig?.mainFontFamily, '', true)}
        ${propStyle('text-align', jsonConfig.body.text.textAlign)}
      }
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--msg-success {
        ${mapStyleToTextCss(jsonConfig.body.text, true)}
        ${propStyle('font-family', jsonConfig?.mainFontFamily, '', true)}
        ${propStyle('text-align', jsonConfig.body.text.textAlign)}
      }
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--checkbox {
        ${mapStyleToTextCss(jsonConfig.body.text, true)}
        ${propStyle('font-family', jsonConfig?.mainFontFamily, '', true)}
      }
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--btn {
        ${mapStyleToTextCss(jsonConfig.button.text, true)}
        ${propStyle('font-family', jsonConfig?.mainFontFamily, '', true)}
        ${propStyle('text-align', jsonConfig.button.text.textAlign, '', true)}
        ${propStyle(
          'background-color',
          jsonConfig.button.backgroundColor,
          '',
          true,
        )}
        ${propStyle(
          'border-radius',
          jsonConfig.button?.border?.radius,
          'px',
          true,
        )}
        ${propStyle('border-color', jsonConfig.button?.border?.color, '', true)}
        ${propStyle(
          'border-width',
          jsonConfig.button?.border?.size,
          'px',
          true,
        )}
        ${propStyle('border-style', 'solid', '', true)}
        ${propStyle('width', jsonConfig.button.width, '', true)}
        ${propStyle(
          'box-shadow',
          `0 7px 15px  ${hexToRgbA(jsonConfig.button.backgroundColor, 0.26)}`,
          '',
          true,
        )}
      }
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--footer-text {
        ${mapStyleToTextCss(jsonConfig.footer.text, true)}
        ${propStyle('font-family', jsonConfig?.mainFontFamily, '', true)}
        ${propStyle('text-align', jsonConfig.footer.text.textAlign, '', true)}
        ${propStyle('margin-bottom', premium ? 0 : 80, 'px', true)}
        ${propStyle('margin-top', 20, 'px', true)}
        ${propStyle('color', jsonConfig?.footer?.text?.font?.color, '', true)}
        ${propStyle(
          'font-size',
          jsonConfig?.footer?.text?.font?.fontSize,
          'px',
          true,
        )}
        ${propStyle(
          'line-height',
          jsonConfig?.footer?.text?.font?.fontSize,
          'px',
          true,
        )}
      }
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--input-title {
        ${propStyle('font-family', jsonConfig?.mainFontFamily, '', true)}
        ${mapStyleToTextCss(jsonConfig.body?.inputFieldStyle.label, true)}
  
        ${propStyle(
          'text-align',
          jsonConfig.body.inputFieldStyle.label.textAlign,
        )}
      }
  
      .md-form-overriden-${formDefinition.id} .md-embedded-form--input-el {
        ${propStyle(
          'background',
          jsonConfig?.body?.inputFieldStyle?.backgroundColor,
        )}
        ${propStyle(
          'border-radius',
          jsonConfig?.body?.inputFieldStyle?.border?.radius,
          'px',
        )}
        ${propStyle(
          'border-color',
          jsonConfig?.body?.inputFieldStyle?.border?.color,
        )}
        ${propStyle(
          'border-width',
          jsonConfig?.body?.inputFieldStyle?.border?.size,
          'px',
        )}
        ${propStyle('border-style', 'solid')}
        ${propStyle('color', jsonConfig?.body?.inputFieldStyle?.color)}
        ${propStyle(
          '--placeholder-color',
          jsonConfig?.body?.inputFieldStyle?.color,
        )}
      }

      .md-form-overriden-${formDefinition.id} .md-embedded-form--select {
        ${propStyle(
          'background',
          jsonConfig?.body?.inputFieldStyle?.backgroundColor,
        )}
        ${propStyle(
          'border-radius',
          jsonConfig?.body?.inputFieldStyle?.border?.radius,
          'px',
        )}
        ${propStyle(
          'border-color',
          jsonConfig?.body?.inputFieldStyle?.border?.color,
        )}
        ${propStyle(
          'border-width',
          jsonConfig?.body?.inputFieldStyle?.border?.size,
          'px',
        )}
        ${propStyle('border-style', 'solid')}
       
        ${propStyle(
          '--placeholder-color',
          jsonConfig?.body?.inputFieldStyle?.color,
        )}
      }

      .md-form-overriden-${formDefinition.id} .md-embedded-form--select select {
       
        ${propStyle(
          'background',
          jsonConfig?.body?.inputFieldStyle?.backgroundColor,
        )}
      }

      .md-form-overriden-${
        formDefinition.id
      } .md-embedded-form--select select option {
        ${propStyle('color', jsonConfig?.body?.inputFieldStyle?.color)}
      }

      .md-form-overriden-${formDefinition.id} .md-embedded-form--textarea-el {
        ${propStyle(
          'background',
          jsonConfig?.body?.inputFieldStyle?.backgroundColor,
          '',
          true,
        )}
        ${propStyle(
          'border-radius',
          jsonConfig?.body?.inputFieldStyle?.border?.radius,
          'px',
          true,
        )}
        ${propStyle(
          'border-color',
          jsonConfig?.body?.inputFieldStyle?.border?.color,
          '',
          false,
        )}
        ${propStyle(
          'border-width',
          jsonConfig?.body?.inputFieldStyle?.border?.size,
          'px',
          true,
        )}
        ${propStyle('border-style', 'solid', '', true)}
        ${propStyle(
          'color',
          jsonConfig?.body?.inputFieldStyle?.color,
          '',
          true,
        )}
        ${propStyle(
          '--placeholder-color',
          jsonConfig?.body?.inputFieldStyle?.color,
          '',
          true,
        )}
      }
  
      .md-form-overriden-${
        formDefinition.id
      } .md-embedded-form--subscriber-counter--text {
        ${propStyle(
          'text-align',
          jsonConfig?.body?.subscriberCounterStyle?.text?.textAlign,
        )}
        ${propStyle(
          'color',
          jsonConfig?.body?.subscriberCounterStyle?.text?.font?.color,
        )}
        ${propStyle(
          'font-size',
          jsonConfig?.body?.subscriberCounterStyle?.text?.font?.fontSize,
          'px',
        )}
        ${propStyle(
          'line-height',
          jsonConfig?.body?.subscriberCounterStyle?.text?.font?.fontSize,
          'px',
        )}
        ${propStyle(
          'font-weight',
          jsonConfig?.body?.subscriberCounterStyle?.text?.font?.fontWeight,
        )}
        ${propStyle(
          'display',
          formDefinition.subscriberCount || formDefinition.subscriberCount === 0
            ? 'block'
            : 'none',
        )}
        ${propStyle('margin-top', '10px')}
      }
  
      .md-form-overriden-${
        formDefinition.id
      } .md-embedded-form--progress-bar-percent-text {
        ${propStyle(
          'text-align',
          jsonConfig?.body?.progressBarPercentStyle?.text?.textAlign,
        )}
        ${propStyle(
          'color',
          jsonConfig?.body?.progressBarPercentStyle?.text?.font?.color,
        )}
        ${propStyle(
          'font-size',
          jsonConfig?.body?.progressBarPercentStyle?.text?.font?.fontSize,
          'px',
        )}
        ${propStyle(
          'line-height',
          jsonConfig?.body?.progressBarPercentStyle?.text?.font?.fontSize,
          'px',
        )}
        ${propStyle(
          'font-weight',
          jsonConfig?.body?.progressBarPercentStyle?.text?.font?.fontWeight,
        )}
        ${propStyle('margin', 7, 'px')}
      }
  
      .md-embedded-form--variant-popup {
        ${propStyle('max-width', 600, 'px')}
      }
  `
  return css
}
