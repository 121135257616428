import { SubmitFormService } from '../types'

export async function submitFormService(values: SubmitFormService) {
  const response = await fetch(
    `${process.env.PREACT_APP_BACKEND_URL}/subscribe`,
    {
      method: 'POST',
      body: new URLSearchParams(values),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
    },
  )

  // if (!response.ok) {
  //   throw await response.json()
  // }
  const data = await response.json()
  return data
}
