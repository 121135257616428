import { FormFieldDefintion } from '../types'
import { h } from 'preact'
import { hexToRgbA } from '../utils/hexToRgba'
import { useAppContext } from '../hooks/useAppContext'

export const DropdownField = ({ fieldDefintion }: FormFieldDefintion) => {
  const { formDefinition } = useAppContext()
  return (
    <label class="md-embedded-form--input-wrapper">
      {fieldDefintion.label && (
        <div class="md-embedded-form--input-title">{fieldDefintion.label}</div>
      )}
      <div class="md-embedded-form--select">
        <select
          name={`fields[${fieldDefintion.indexPosition}].value`}
          class="md-embedded-form--dropdown-el"
          tabIndex={fieldDefintion.indexPosition}
          required={fieldDefintion.isRequired}
          data-id="maildroppa--select"
          type={fieldDefintion.dataType}
          style={{
            color: hexToRgbA(
              formDefinition.jsonConfig?.body?.inputFieldStyle?.color,
              0.4,
            ),
          }}
        >
          <option selected disabled hidden value="">
            {fieldDefintion.placeholder || ''}
          </option>
          {fieldDefintion.optionValues &&
            fieldDefintion.optionValues.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
        </select>
        <input
          value={fieldDefintion.name}
          name={`fields[${fieldDefintion.indexPosition}].name`}
          type="hidden"
        />
      </div>
    </label>
  )
}
